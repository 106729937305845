/* CSS (GeneralContact.css) */
/* General styling for the contact page */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #fff;
  background-color: #004400;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.contact-item .icon {
  margin-right: 10px;
  font-size: 1.5rem;
  color: #00aaff;
}

.contact-form {
  width: 100%;
  max-width: 1200px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  height: 100px;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #00aaff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0088cc;
}

.location-info {
  width: 100%;
  max-width: 1200px;
  text-align: left;
  margin-bottom: 20px;
}

.location-info h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.location-content {
  display: flex;
  flex-direction: column;
}

.location-image,
.location-map {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.location-image img,
.location-map img {
  width: 100%;
  border-radius: 8px;
}

.location-image {
  position: relative;
}

.location-image .location-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
  padding: 10px;
  font-size: 1.2rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.map-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #00aaff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.map-button:hover {
  background-color: #0088cc;
}

@media (min-width: 768px) {
  .contact-info {
    flex-direction: row;
    justify-content: space-between;
  }
  .location-content {
    flex-direction: row;
    justify-content: space-between;
  }
  .location-image,
  .location-map {
    width: 48%;
  }
}
