.collaboration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #fff;
  background-color: #004400;
}

.collaboration-container h2 {
  margin-bottom: 20px;
  font-size: 2rem;
}

.collaboration-container .radio-group {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.collaboration-container .radio-group input[type="radio"] {
  margin-right: 10px;
  flex-shrink: 0;
}

.collaboration-container .radio-group label {
  margin: 0;
  text-align: left;
  line-height: 1.5;
}

form {
  width: 80%;
  max-width: 600px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #333;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

.form-group p {
  margin-bottom: 5px;
}

.radio-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.radio-group input[type="radio"] {
  margin-right: 10px;
  flex-shrink: 0;
}

.radio-group label {
  margin: 0;
  flex-grow: 1;
  text-align: left;
  line-height: 1.5;
  word-wrap: break-word;
  display: block;
  width: auto;
  white-space: normal;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #00aaff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0088cc;
}