/* General Styling */
body, html {
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

.home-container {
  text-align: center;
  width: 100%;
  padding: 0 20px;
}

/* Video Section (Top) */
.video-section {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 70vh;
  overflow: hidden;
  border-radius: 8px;
}

.lab-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-text-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: green;
  text-shadow: 0 0 10px grey;
  text-align: center;
  padding: 10px;
}

.video-text-overlay {
  position: absolute;
  top: 20px; /* Add margin from the top */
  left: 40px; /* Add margin from the left */
  bottom: 20px; /* Add margin from the bottom */
  background-color: black; /* Green background color */
  color: white; /* White text for contrast */
  padding: 20px; /* Add internal spacing */
  border-radius: 15px;
  width: 45%; /* Slightly wider text box */
  height: 37%; /* Occupy the full height of the video minus margins */
  line-height: 1.8; /* Adjust line spacing for readability */
  z-index: 1; /* Keep the text above the video */
  overflow-y: auto; /* Add scroll if text exceeds container height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for better readability */
}

.row-video {
  width: 100%;
  height: auto;
  border-radius: 15px;
  object-fit: cover;
}

/* Enhanced Box Style */
.enhanced-box {
  border-radius: 150px;
  padding: 30px;
  background-color: #e0e0e0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  margin: 40px auto;
  width: 100%;
}

.enhanced-box h2 {
  margin-bottom: 20px;
}

.spaced {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
}

.clients-row .client-types {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
}

.client {
  text-align: center;
  max-width: 300px;
  flex: 1;
}

.client-icon {
  width: 140px;
  height: 140px;
  margin-bottom: 15px;
  border-radius: 50%;
  object-fit: cover;
}

.service, .client {
  text-align: center;
  max-width: 300px;
}

.service-icon, .client-icon {
  width: 140px;
  height: 140px;
  margin-bottom: 15px;
  border-radius: 50%;
  object-fit: cover;
}

/* Time Saving Row with Images */
.highlight-row {
  display: flex;
  width: 100%;
  margin: 0px auto;
  overflow: hidden;
  padding: 0;
  gap: 0;
  margin-left: 2px !important; 
  border-radius: 0px !important;
}

.image-with-text {
  position: relative;
  flex: 1;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: block;
  color: white;
  border-radius: 0px !important;
}

.image-with-text .half-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px !important;
}

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-shadow: 0 0 10px green;
  text-align: center;
  border-radius: 10px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.image-with-text:hover .half-image {
  filter: brightness(50%);
}

/* Bottom Video Row */
.video-centered {
  margin: 40px auto;
  padding: 0;
  position: relative;
}

.row-video {
  width: 100%;
  border-radius: 0px !important;
  object-fit: cover;
}

.video-centered .video-text {
  position: absolute;
  top: 5%; /* Top-left positioning */
  left: 5%;
  transform: none;
  width: 30%; /* Adjust the width */
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: left;
  border-radius: 0px !important;
  z-index: 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Vision Section Styling */
.vision-row {
  position: relative; /* Ensure text aligns with the image */
  margin: 40px auto;
  padding: 0;
  width: 100%; /* Ensure full width for the container */
  border-radius: 15px;
  overflow: hidden;
}

.row-vision {
  width: 100%; /* Ensure the image spans full width */
  height: auto;
  object-fit: cover;
}

.vision-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80% !important; /* Force reduced width */
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  border-radius: 10px;
  z-index: 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.last-image {
  border-radius: 8px;
}

.video-centered .video-text p {
  font-size: 1.6rem; /* Adjust to your preferred size */
  line-height: 1.4;
}

.video-centered .video-text h2 {
  font-size: 1.2rem; /* Or your desired size for <h2> elements */
}

/* Responsive Adjustments */
@media (max-width: 768px) {

  .video-section {
    padding: 0; /* Ensure no padding around the video */
    margin: 0; /* Ensure no margin around the section */
  }

  .highlight-row {
    flex-direction: column;
  }

  .video-text-title h1 {
    font-size: 1.2rem; /* Adjust to your preferred size */
    line-height: 1.4;
  }

  .video-centered .video-text p {
    font-size: 1.2rem; /* Adjust to your preferred size */
    line-height: 1.4;
  }

  .spaced, 
  .clients-row .client-types {
    flex-direction: column; /* Stack items vertically */
    gap: 20px; /* Add spacing between items */
  }

  .image-with-text .half-image {
    border-radius: 15px 15px 0 0;
  }

  .scrollable-text {
    max-height: 200px;
    overflow-y: scroll;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 8px;
  }

  /* Fix text box for the last video */
  .video-centered .video-text {
    position: relative; /* Keep in document flow */
    margin: 0; /* Remove all extra margins */
    padding: 10px 15px; /* Add internal spacing */
    width: 100%; /* Ensure full width */
    max-width: 100%; /* Prevent exceeding container width */
    box-sizing: border-box; /* Include padding in width */
    background-color: rgba(0, 0, 0, 0.8); /* Background for readability */
    color: white; /* Text color */
    border-radius: 1; /* Remove rounded corners */
    text-align: center; /* Center-align text */
    z-index: 2; /* Ensure it stays above the image */
    overflow-wrap: break-word; /* Prevent text overflow */
}

.last-video {
    position: relative; /* Keep in flow of the document */
    margin: 0; /* Remove spacing above video */
    width: 100%; /* Match video width */
    left: 5%;
    padding: 10px 15px; /* Add internal spacing */
    box-sizing: border-box; /* Ensure width includes padding */
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    border-radius: 1; /* Remove rounded corners */
}

/* Fix for last image and text alignment */
.video-centered {
  margin-bottom: 20px; /* Add a small margin below the video */
  margin-left: -20px !important;
  margin-right: 20px !important;
}

.vision-row {
  flex-direction: column; /* Keep stacked layout */
  position: relative; /* Ensure normal flow */
  width: 107% !important;
  border-radius: 0px !important;
  padding: 10px 15px; /* Add internal spacing */
  margin-left: -10px !important;
}

.vision-text {
  position: static; /* Remove absolute positioning */
  transform: none; /* Reset transformation */
  width: 100% !important; /* Match the width of the image */
  max-width: 600px;
  margin-bottom: 0px; /* Add spacing below the text */
  margin-left: -3px !important;
  margin-right: 10px !important;
  padding: 10px 15px; /* Add internal spacing */
  background-color: rgba(0, 0, 0, 0.8); /* Darker background for visibility */
  color: white;
  line-height: 1.6;
  border-radius: 0px !important;
  text-align: center;
}

.last-image {
  width: 100% !important; /* Ensure the image takes the full width of its container */
  max-width: 600px; /* Restrict to the same max width as the text box */
  height: auto; /* Maintain the aspect ratio of the image */
  margin: 0 !important; /* Remove default margins */
  margin-left: -11px !important; /* Shift the image slightly to the left */
  display: block; /* Ensure the image is treated as a block element */
  border-radius: 0px !important; /* Ensure no rounding */
  padding: 0 !important; /* Remove any internal padding if applied */
}
}