/* File: AboutUs.css */
.inside-protforge {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 20px;
  line-height: 1.6;
}

/* Lab Mission Section */
.lab-mission-section {
  position: relative;
  text-align: center;
}

.lab-mission-image {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  filter: brightness(0.5);
}

.lab-mission-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 20px;
}

.lab-mission-title {
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.lab-mission-text {
  font-size: 2em;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

/* Mission Text Section */
.mission-text-section {
  margin: 40px 0;
  padding: 40px;
  background: linear-gradient(135deg, #3a3a3a, #555);
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  color: white;
  text-align: left;
  line-height: 1.8;
}

.mission-title {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: green;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.mission-text-section p {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

/* How It Is Going Section */
.how-it-is-going-section {
  margin: 40px 0;
  text-align: center;
}

.section-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}

.how-it-is-going-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Locations Row */
.locations-row {
  width: 100%;
}

.locations-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Achievements Row */
.achievements-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.our-achievements {
  max-width: 600px;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.achievement-text h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.achievement-text p {
  font-size: 1em;
  color: #333;
}

/* AI Path Section */
.ai-path-section {
  position: relative;
  margin: 40px 0;
  text-align: center;
}

.ai-path-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  color: black;
  padding: 20px 0;
  z-index: 1;
  border-radius: 12px 12px 0 0;
}

.ai-path-title {
  font-size: 2em;
  font-weight: bold;
  margin: 0;
}

.ai-path-image {
  width: auto; /* Automatically adjust width to maintain aspect ratio */
  height: 90vh; /* Set height to 90% of the viewport height */
  object-fit: contain; /* Ensure the entire image is visible */
  border-radius: 0 0 12px 12px; /* Retain rounded bottom corners */
  display: block; /* Center alignment fix */
  margin: 0 auto; /* Horizontally center the image if it's not full width */
}

/* Founder Section */
.founder-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  padding: 40px;
  background: linear-gradient(135deg, #3a3a3a, #555);
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  color: white;
}

.founder-quote {
  flex: 1;
  font-size: 1.5em;
  line-height: 1.8;
  padding-right: 20px;
  text-align: left;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.founder-quote p {
  margin-bottom: 20px;
  font-style: italic;
}

.quote-author {
  display: block;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 10px;
  text-align: left;
  opacity: 0.9;
}

.founder-image-container {
  flex: 0 0 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.founder-image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 4px solid white;
}

/* AI Path Section: Adjust for Phone Screens */
@media (max-width: 768px) {
  .ai-path-image {
    width: 100%; /* Ensure the image scales to fit the screen width */
    height: auto; /* Maintain the aspect ratio */
    object-fit: contain; /* Ensure the entire image is visible */
    border-radius: 12px; /* Retain rounded corners */
  }
}

/* Founder Section: Adjust Layout for Phone Screens */
@media (max-width: 768px) {
  .founder-section {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align content */
    text-align: center; /* Center-align text */
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .founder-quote {
    padding-right: 0; /* Remove side padding */
    padding-bottom: 20px; /* Add spacing between text and image */
  }

  .founder-image-container {
    margin-left: 0; /* Remove side margin */
  }
}