/* General header styling */
header {
  background-color: darkgreen;
  color: white;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

main {
  flex: 1;
  padding-top: 6rem;
}

/* Logo title styling */
header .logo-title {
  display: flex;
  align-items: center;
}

header img {
  height: 50px;
  filter: invert(100%);
}

header h1 {
  margin: 0 10px;
  font-size: 1.25rem;
  flex-shrink: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

header h1 a {
  color: white;
  text-decoration: none;
}

/* Desktop Navigation Styles */
nav {
  flex-grow: 1;
  text-align: center;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.nav-links li {
  display: inline;
  margin: 0 20px;
  position: relative;
}

.nav-links li a,
.dropbtn,
.dropdown-content a {
  color: white;
  text-decoration: none;
  font-size: 1.25rem; /* Ensure a consistent font size for all navigation items */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  outline: none; /* Remove the blue focus rectangle */
  background-color: darkgreen; /* Make sure dropdown buttons match the overall theme */
  border: none; /* Remove border to eliminate the white line */
}

/* Add custom focus styles to match the design */
.nav-links li a:focus,
.dropbtn:focus,
.dropdown-content a:focus {
  outline: none; /* Remove default browser outline */
  border-bottom: none;
}

/* Hover effect for desktop - show suboptions */
.nav-links li:hover .dropdown-content {
  display: block;
}

/* Submenu (dropdown) content */
.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: darkgreen; /* Match header background */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 8px;
}

/* Submenu links */
.dropdown-content a {
  padding: 12px 16px;
  display: block;
  border-radius: 8px;
  outline: none; /* Remove focus rectangle on dropdown links */
}

/* Submenu hover effect */
.dropdown-content a:hover {
  background-color: #ddd;
  color: darkgreen;
}

/* Remove arrows from dropdown links on desktop */
@media screen and (min-width: 769px) {
  .dropdown-content {
    display: none;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    color: darkgreen;
  }

  .dropdown .arrow {
    display: none;
  }

  .dropbtn {
    background-color: darkgreen;
  }
}

/* Language dropdown hover effect */
.language-dropdown {
  margin-left: auto;
  position: relative;
}

.language-dropdown .dropbtn {
  font-size: 1rem;
  color: silver;
  text-decoration: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none; /* Remove focus outline */
}

.language-dropdown .dropbtn:focus {
  outline: none; /* Remove the default outline on focus */
}

.language-dropdown .dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 8px;
}

.language-dropdown:hover .dropdown-content {
  display: block;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  font-size: 1.5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  outline: none; /* Remove focus outline */
}

.hamburger:focus {
  outline: none; /* Remove the default outline on focus */
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  nav {
    display: none;
    flex-direction: column;
  }

  nav.open {
    display: flex;
    background-color: darkgreen;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 2rem;
    z-index: 1000;
    overflow-y: auto;
  }

  .nav-links {
    display: block;
    text-align: left;
    width: 100%;
  }

  .nav-links li {
    margin: 10px 0;
    display: block;
  }

  .nav-links li a,
  .dropbtn {
    color: white;
    background-color: darkgreen;
    font-size: 1.25rem;
    padding: 10px;
    display: block;
    width: 100%;
    text-align: left;
    outline: none; /* Remove focus outline */
  }

  .nav-links li a:focus,
  .dropbtn:focus {
    outline: none; /* Remove the default outline on focus */
  }

  .dropdown-content {
    display: none;
    padding: 10px;
  }

  .dropdown-content.show {
    display: block;
  }

  .dropdown-content a {
    padding: 10px;
    background-color: darkgreen;
    color: white;
    border: none;
    outline: none; /* Remove focus outline */
  }

  .dropdown-content a:focus {
    outline: none; /* Remove focus outline */
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: darkgreen;
  }

  .dropdown .arrow {
    margin-left: 10px;
    display: inline;
  }

  .hamburger {
    display: block;
  }

  .mobile-menu-header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid white;
  }

  .mobile-menu-logo {
    height: 40px;
    margin-right: 10px;
  }

  .mobile-menu-header h1 {
    color: white;
  }
}