/* src/pages/CaseStudies.css */

/* General styling for the Case Studies page */
.case-studies-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #fff;
  background-color: #004400; /* Dark green background color */
}

/* Content area for the case studies */
.tab-content {
  width: 100%;
  max-width: 800px; /* Increase max width for larger screens */
}

/* Case study block styling */
.case-study-block {
  width: 100%;
  background-color: #002200; /* Slightly darker green for the block background */
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.case-study-title {
  color: #00ff00; /* Light green for links */
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.case-study-title:hover {
  text-decoration: underline;
}

.case-study-description {
  font-size: 1.2rem;
  color: white;
  margin-top: 10px;
}

/* Responsive styling for smaller screens */
@media (max-width: 600px) {
  .case-study-description {
    font-size: 1rem; /* Smaller font size for mobile devices */
  }

  .tab-content {
    max-width: 100%; /* Ensure content fits properly on smaller screens */
  }
}
