.privacy-policy {
  padding: 2rem;
  line-height: 1.6;
}

.privacy-policy h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.privacy-policy h2 {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.privacy-policy p {
  margin-bottom: 1rem;
}

.privacy-policy ul {
  list-style: disc;
  padding-left: 20px;
}

.privacy-policy ul li {
  margin-bottom: 0.5rem;
}