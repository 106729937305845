/* src/pages/OurTools.css */
.our-tools {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.video-section {
  position: relative;
  width: 100%;
  height: auto;
}

.background-video {
  width: 100%;
  height: auto;
}

.video-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.video-text h1 {
  font-size: 5rem;
  margin: 0;
}

.video-text p {
  font-size: 2rem;
}

.about-ai,
.about-data {
  padding: 2rem;
  text-align: center;
  background: #c0c0c0;
  margin: 2rem;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-ai h2,
.about-data h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.ai-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.ai-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  background: #e0e0e0;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ai-column h3 {
  background: #e0e0e0;
  padding: 0.5rem;
  border-radius: 8px;
  margin: 0;
  text-align: center;
  font-size: 1.75rem;
  font-weight: bold;
}

.ai-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.ai-description {
  width: 50%;
  text-align: center;
  font-size: 1.5rem;
}

.ai-image {
  width: 50%;
  height: auto;
}

.ai-enumeration {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 50%;
  font-size: 1.5rem;
}

.data-row {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 2rem;
  flex-wrap: wrap;
}

.data-column {
  max-width: 30%;
  text-align: center;
  background: #e0e0e0;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
}

.data-column h3 {
  background: #e0e0e0;
  padding: 0.5rem;
  border-radius: 8px;
  margin: 0;
  text-align: center;
  font-size: 1.75rem;
  font-weight: bold;
}

.playable-video-section {
  margin: 2rem 0;
  text-align: center;
}

.playable-video {
  width: 80%;
  height: auto;
}

.final-video-section {
  position: relative;
  margin: 2rem 0;
  text-align: center;
}

.final-video {
  width: 100%;
  height: auto;
}

.final-video-section .final-video-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.final-video-section .final-video-text h1 {
  font-size: 3rem;
  margin: 0;
}

.final-video-section .final-video-text p {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .ai-row,
  .data-row {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    margin: 0; /* Remove external margins */
  }

  .ai-column,
  .data-column {
    width: 100%; /* Utilize full width */
    max-width: none; /* Remove any restrictions on max-width */
    padding: 0.5rem; /* Reduce padding */
  }

  .about-ai,
  .about-data {
    margin: 0 0 1rem; /* Add bottom margin to create space between sections */
    padding: 1rem; /* Reduce inner padding */
  }

  .ai-description,
  .ai-image,
  .ai-enumeration {
    width: 100%; /* Make each element span the entire width */
    font-size: 1.25rem; /* Slightly smaller text for better fit */
  }

  .final-video-section {
    display: flex;
    flex-direction: column; /* Stack text and video vertically */
    align-items: center;
    text-align: center;
    background-color: #e0e0e0; /* Set background color */
    padding: 1rem; /* Add padding for space around the text */
    margin: 0.5rem; /* Small margin for left and right on the container */
    border-radius: 10px; /* Add rounded corners */
  }

  .final-video-section .final-video-text {
    position: static; /* Remove absolute positioning for the text */
    transform: none; /* Reset transformation */
    margin-bottom: 1rem; /* Add space between text and video */
    color: black; /* Set text color to black */
  }

  .final-video-section .final-video-text h1 {
    font-size: 1.5rem; /* Adjust title size for better fit */
  }

  .final-video-section .final-video-text p {
    font-size: 0.875rem; /* Adjust description size */
  }

  .final-video {
    width: 90%; /* Reduce video width for mobile */
  }
}