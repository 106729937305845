/* DynamicPage.css */

.dynamic-page-container {
  margin: 0 auto;
  padding: 20px; /* Adds some margin on the left and right */
  max-width: 1200px; /* Limits the width of the content for better readability */
}

.dynamic-page-container h1, 
.dynamic-page-container h2 {
  margin-bottom: 20px; /* Adds space between the title/subtitle and the image */
}

.image-text-wrapper {
  display: flex;
  align-items: flex-start; /* Align items at the top */
  gap: 20px; /* Adds some space between the image and the text */
}

.dynamic-page-image {
  width: 30%; /* Image occupies 30% of the container's width */
  height: auto; /* Maintains the aspect ratio */
}

.dynamic-page-content {
  flex: 1; /* The content takes up the remaining space */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .image-text-wrapper {
    display: block; /* Stack elements vertically */
  }

  .dynamic-page-image {
    width: 100%; /* Image takes the full width of the container */
    margin-bottom: 20px; /* Adds space between the image and the text */
  }

  .dynamic-page-content {
    width: 100%; /* Content also takes full width */
  }
}

@media (max-width: 480px) {
  .dynamic-page-container {
    padding: 10px; /* Reduce padding for very small screens */
  }

  .dynamic-page-container h1, 
  .dynamic-page-container h2 {
    margin-bottom: 15px; /* Reduce spacing slightly for smaller screens */
  }
} 