/* Main background should be dark green */
.blog-section {
  background-color: #004400;
  color: #ffffff;
  padding-top: 20px;
  padding-bottom: 40px;
}

/* Center the newsletter-signup section with a light green background and space between input and button */
.newsletter-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #006600;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
  width: 100%;
  max-width: 600px; /* Increased max-width to make it wider */
  margin-left: auto;
  margin-right: auto;
}

.newsletter-signup h3 {
  margin-bottom: 15px;
  color: #ffffff;
}

.newsletter-signup form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center; /* Align items vertically centered */
}

.newsletter-signup input {
  padding: 10px;
  width: calc(100% - 180px); /* Adjusted width to make room for the dropdown */
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 10px; /* Add space between input and dropdown */
}

.newsletter-signup select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 10px; /* Add space between dropdown and button */
  width: 120px; /* Set a fixed width for the dropdown */
}

.newsletter-signup button {
  padding: 10px;
  background-color: #00aaff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 120px; /* Adjust button width */
}

.newsletter-signup button:hover {
  background-color: #0088cc;
}

/* Blog post container */
.blog-post {
  display: flex;
  background-color: #006600;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
}

.blog-post img {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.blog-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-content h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #ffffff;
}

.blog-content p {
  margin: 5px 0;
  color: #ffffff;
}

.blog-content a {
  margin-top: 10px;
  color: #00aaff;
  text-decoration: none;
  font-weight: bold;
}

.blog-content a:hover {
  color: #0088cc;
}

/* Mobile styles to improve layout on smaller devices */
@media (max-width: 768px) {
  /* Stack blog post image and content vertically */
  .blog-post {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start */
    text-align: center; /* Center text for a more cohesive mobile look */
  }

  .blog-post img {
    width: 100%; /* Full width for better visibility on mobile */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Space between image and content */
  }

  .blog-content {
    align-items: center; /* Center content vertically */
    width: 100%; /* Ensure content takes full width */
  }

  .blog-content h3 {
    font-size: 1.25rem; /* Reduce font size slightly for mobile */
  }

  .blog-post {
    margin-left: 0;
    margin-right: 0; /* Remove side margins to maximize space */
    padding: 15px; /* Reduce padding for a more compact look */
  }
}