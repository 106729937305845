footer {
  background-color: darkgreen;
  color: white;
  text-align: center;
  padding: 1rem;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.footer-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bar a {
  color: white;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icons a,
.social-icons a i {
  color: white;
  font-size: 1.5rem;
}

/* Responsive */
@media (max-width: 768px) {
  .footer-bar {
    flex-direction: column;
    gap: 10px;
  }
}